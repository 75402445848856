import * as React from "react"
import Layout from "../components/layout"
import { graphql, useStaticQuery } from "gatsby"

const query = graphql`
query InfoQuery {
  allMarkdownRemark(filter: {fileAbsolutePath: {regex: "/info/"}}) {
    edges {
      node {
        frontmatter {
          text
        }
      }
    }
  }
}
`



// markup
const InfoPage = () => {
    const data = useStaticQuery(query)
    const text = data.allMarkdownRemark.edges[0].node.frontmatter.text 

    const Tekst = () => {
        return { __html: `${ text }` }
    }

  return (
    <Layout pageTitle={'Chiro Info'}>
        <div class="px-2 md:px-0 py-8" dangerouslySetInnerHTML={Tekst()}></div>
    </Layout>
  )
}

export default InfoPage
